import md5 from 'js-md5'
import store from "@/store";

export function getSingString(data){
    let str = ''
    for (const key in data){
        let value = data[key];
        if (value === null || value === undefined) {
            continue
        }
        if (typeof value == 'object') {
            value = getSingString(value)
        }
        str = str + value
    }
    return str

}

export function makeSign(inObject) {
    delete inObject.time;
    delete inObject.sign;
    inObject.time = getTime();
    const sorter = function (inObject) {
        const sortedJson = {};
        const sortedKeys = Object.keys(inObject).sort();
        for (let i = 0; i < sortedKeys.length; i++) {
            sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
        }
        return sortedJson;
    }
    const sortedParam = sorter(inObject);
    let needSignatureStr = getSingString(sortedParam);
    const key = process.env.VUE_APP_SIGN_KEY;
    return md5(md5(needSignatureStr + key) + key);
}

function getTime() {
    let date = new Date();   //将时间格式转为字符串
    return Date.parse(date.toString()) / 1000
}
export async function getUserInfo() {
    if (Object.keys(store.state.userInfo).length === 0) {
        await store.dispatch('getUserInfo')
    }
    return Object.keys(store.state.userInfo).length;
}

export async function getStsToken() {
    if (store.state.stsToken.AccessKeyId && (getTime() + 300) < store.state.stsToken.Expiration) {
        return store.state.stsToken
    }else{
        await store.dispatch('getStsToken')
    }
    return store.state.stsToken

}

export function authCheckRouter(arrObj,auth = 0){
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('uid');
    if (!id || !token) {
        return false
    }
    if (!store.state.getAuthRuleStatus) {
        store.dispatch('getRuleList')
    }
    let newArrObj =  arrObj.filter((data)=>{
        return Object.values(store.state.authRule).includes(data.url) && (!auth || !data?.auth || data?.auth === auth)
    })
    return newArrObj;
}
export function authCheck(auth) {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('uid');
    if (!id || !token) {
        return false
    }
    if (!store.state.getAuthRuleStatus) {
        store.dispatch('getRuleList')
    }
    return Object.values(store.state.authRule).includes(auth);
}
// 单向数据处理
export async function asyncAuthCheck(auth) {
    if (store.state.authRule.length === 0) {
        await store.dispatch('getRuleList')
    }
    return Object.values(store.state.authRule).indexOf(auth) >= 0;
}