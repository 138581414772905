// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/2
// +-------------------------------------------------------------
// |Time: 14:40
// +-------------------------------------------------------------
export default [
    {
        path: "/game/game-list",
        name: "GameList",
        meta: {
            title: "游戏列表",
            menu:true,
        },
        component: () => import("@/views/game/Game/GameList.vue")
    },
    {
        path: "/game/discount-game",
        name: "DiscountGame",
        meta: {
            title: "0.1折游戏推广页",
            menu:true,
            level:2
        },
        component: () => import ("@/views/game/DiscountGame.vue")
    },
    {
        path: "/game/discount-game-set",
        name: "DiscountGameSet",
        meta: {
            title: "0.1折自助设置",
            menu:true,
            level:2
        },
        component: () => import ("@/views/game/DiscountGameSet.vue")
    },
    // {
    //     path: "/game/unapply-game-list",
    //     name: "unapplyGameList",
    //     meta: {
    //         title: "申请游戏",
    //         menu:true,
    //     },
    //     component: () => import("@/views/game/unapply/List.vue")
    // },
    // {
    //     path: "/game/my-game-list",
    //     name: "myGameList",
    //     meta: {
    //         title: "我的游戏",
    //         menu:true,
    //     },
    //     component: () => import("@/views/game/myGame/List.vue")
    // },
    {
        path: "/game/third-game",
        name: "thirdGameList",
        meta: {
            title: "代充游戏",
            menu:true,
        },
        component: () => import("@/views/game/thirdGame/List.vue")
    },
    {
        path: "/game/child-apply-game",
        name: "childApplyGame",
        meta: {
            title: "子渠道游戏",
            menu:true,
        },
        component: () => import("@/views/game/childApply/List.vue")
    },
    {
        path: "/game/active/:game_id/:type",
        name: "GameActiveList",
        meta: {
            title: "游戏活动",
            menu:true,
        },
        component: () => import("@/views/game/GameInfo/GameActive")
    },
    {
        path: "/game/server/:game_id/:type",
        name: "GameServerList",
        meta: {
            title: "游戏开服表",
            menu:true,
        },
        component: () => import("@/views/game/GameInfo/GameServer.vue")
    },
    {
        path: "/game/vip-list/:game_id/:type",
        name: "GameVipList",
        meta: {
            title: "游戏VIP表",
            menu:true,
        },
        component: () => import("@/views/game/GameInfo/GameVipList.vue")
    },
    {
        path: "/game/server-img/:game_id/:type",
        name: "GameServeImg",
        meta: {
            title: "游戏区服截图",
            menu:true,
        },
        component: () => import("@/views/game/GameInfo/GameServeImg.vue")
    },
    {
        path: "/game/discount/:game_id/:type/:pid?",
        name: "GameDiscount",
        meta: {
            title: "代充成本",
            menu:true,
        },
        component: () => import("@/views/game/GameInfo/GameDiscount.vue")
    },
]
