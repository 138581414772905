// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/12
// +-------------------------------------------------------------
// |Time: 14:45
// +-------------------------------------------------------------
export default [
    {
        path: "/recharge/child-recharge",
        name: "childSendBalance",
        meta: {
            title: "子渠道加币",
            menu:true,
        },
        component: () => import("@/views/promoteCoin/SendBalance.vue")
    },
    {
        path: "/child/promote-list",
        name: "childList",
        meta: {
            title: "子渠道列表",
            menu:true,
        },
        component: () => import("@/views/child/List.vue")
    },
    {
        path: "/child/discount-list",
        name: "childDiscountList",
        meta: {
            title: "子渠道分成",
            menu:true,
        },
        component: () => import("@/views/childDiscount/List.vue")
    },
    {
        path: "/child/operate-discount/:ids?",
        name: "OperateChildDiscount",
        meta: {
            title: "子渠道分成编辑/添加",
            menu:true,
        },
        component: () => import("@/views/childDiscount/Operate.vue")
    },
    {
        path: "/child/edit-once-discount/:gid/:pid",
        name: "editOnceChildDiscount",
        meta: {
            title: "编辑独立游戏子渠道分成",
            menu:true,
        },
        component: () => import("@/views/childDiscount/EditOnce.vue")
    }
]
