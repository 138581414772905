// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/13
// +-------------------------------------------------------------
// |Time: 16:53
// +-------------------------------------------------------------
export default [
    {
        path: "/recharge/white-list",
        name: "whiteList",
        meta: {
            title: "用户折扣白名单",
            menu:true,
        },
        component: () => import("@/views/data/whiteList/List.vue")
    },
    {
        path: "/data/operate-whiteList/:id?",
        name: "whiteListOperate",
        meta: {
            title: "用户折扣白名单编辑/添加",
            menu:true,
        },
        component: () => import("@/views/data/whiteList/Operate.vue")
    },
    {
        path: "/data/user-list",
        name: "userDataList",
        meta: {
            title: "用户明细",
            menu:true,
        },
        component: () => import("@/views/data/user/List.vue")
    },
    {
        path: "/data/operate-user/:id?",
        name: "userDataOperate",
        meta: {
            title: "用户注册",
            menu:true,
        },
        component: () => import("@/views/data/user/Operate.vue")
    },
    {
        path: "/data/spend-list",
        name: "spendDataList",
        meta: {
            title: "消费明细",
            menu:true,
        },
        component: () => import("@/views/data/spend/List.vue")
    },
    {
        path: "/data/user-play-info-list",
        name: "UserPlayInfoList",
        meta: {
            title: "角色列表",
            menu:true,
        },
        component: () => import("@/views/data/UserPlayInfo/List.vue")
    }
]
