// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/6/22
// +-------------------------------------------------------------
// |Time: 13:56
// +-------------------------------------------------------------
export default [
    {
        path: "/wallet/auto-recharge",
        name: "WalletAutoRecharge",
        meta: {
            title: "扫码加币",
            menu:true,
        },
        component: () => import("@/views/recharge/AutoRechargeBalance.vue")
    },
    {
        path: "/wallet/balance-record",
        name: "RechargeBalanceRecord",
        meta: {
            title: "加币订单",
            menu:true,
        },
        component: () => import("@/views/recharge/BalanceRecord.vue")
    },
    {
        path: "/wallet/balance-send",
        name: "sendBalanceRecord",
        meta: {
            title: "平台币记录",
            menu:true,
        },
        component: () => import("@/views/recharge/sendBalanceRecord.vue")
    },
    {
        path: "/recharge/balance/promote-coin-list",
        name: "promoteCoinList",
        meta: {
            title: "加币记录",
            menu:true,
            auth:2,
            level:3
        },
        component: () => import ("@/views/promoteCoin/List.vue")
    },
]
