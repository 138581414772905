// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/8
// +-------------------------------------------------------------
// |Time: 10:23
// +-------------------------------------------------------------
export default [
    {
        path: "/recharge/bind-balance",
        name: "rechargeBindBalance",
        meta: {
            title: "玩家代充",
            menu:true,
        },
        component: () => import("@/views/recharge/bindBalance/Index.vue")
    },
    {
        path: "/recharge/bind-record",
        name: "rechargeBindBalanceRecord",
        meta: {
            title: "代充记录",
            menu:true,
        },
        component: () => import("@/views/recharge/bindBalance/Record.vue")
    },

    {
        path: "/recharge/platform-coin",
        name: "TransferPlatformCoin",
        meta: {
            title: "平台币转赠",
            menu:true,
        },
        component: () => import("@/views/recharge/transfer/TransferPlatformCoin.vue")
    },
    {
        path: "/recharge/transfer-list",
        name: "TransferRecordList",
        meta: {
            title: "转赠记录",
            menu:true,
        },
        component: () => import("@/views/recharge/transfer/TransferRecordList.vue")
    }
]
