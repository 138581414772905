// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/29
// +-------------------------------------------------------------
// |Time: 15:41
// +-------------------------------------------------------------
import {createStore} from "vuex";
import {userInfo} from "@/api/promote";
import {stsToken} from "@/api/tool";
import {utcToTimestamp} from "@/utils/time";
import {ruleList} from "@/api/auth_rule";

export default createStore({
    state:{
        tabBarActive:localStorage.getItem('tabBarActive'),
        theme:'',
        noticeStatus:true,
        // 权限列表
        authRule: [],
        getAuthRuleStatus:false,
        userInfo: [],
        signStatus:false,
        // 阿里云上传
        stsToken: {
            SecurityToken: '',
            AccessKeyId: '',
            AccessKeySecret: '',
            Expiration: '',
        },
    },
    mutations:{
        updateRuleList(state,data) {
            state.authRule = data
        },
        updateGetAuthRuleStatus(state,data) {
            state.getAuthRuleStatus = data
        },
        updateUserInfo(state,data){
            state.userInfo = data
        },
        updateNoticeStatus(state){
            state.noticeStatus = false
        },
        updateStsToken(state,data){
            state.stsToken.SecurityToken = data.SecurityToken;
            state.stsToken.AccessKeyId = data.AccessKeyId;
            state.stsToken.AccessKeySecret = data.AccessKeySecret;
            state.stsToken.Expiration = utcToTimestamp(data.Expiration);
        },
        changeTabBar(state,data){
            localStorage.setItem('tabBarActive', data);
            state.tabBarActive = data
        },
        logout(state) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            state.userInfo = [];
            state.tabBarActive = '';
            state.noticeStatus = true;
            state.signStatus = false
        },
        updateSignStatus(state,data) {
            state.signStatus = data
        },
    },
    actions:{
        async getUserInfo({commit}){
            const {data} = await userInfo()
            commit('updateUserInfo',data)
            return data
        },
        getStsToken({commit}){
            return stsToken().then(res => {
                commit('updateStsToken',res.data.Credentials)
            })
        },
        async getRuleList({commit}){
            const {data} = await ruleList()
            commit('updateRuleList',data)
            commit('updateGetAuthRuleStatus',true)
            return data
        }
    },
    strict:true
})
